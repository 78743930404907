import React from 'react';
import NetlifyForm from '../NetlifyForm';
import AbstractForm from '../AbstractForm';

class NewsletterForm extends AbstractForm {
  constructor(props) {
    super(props)
    this.state = {
      'form-name': 'newsletter',
      locale: props.locale,
      email: ''
    }
  }

  render() {
    if (this.state.status === 'success') {
      return (
        <div className="zmw-Form_Message zmw-Form_Message--success">
          <p>{this.props.labels.messages.success}</p>
        </div>
      )
    } else {
      return (
        <NetlifyForm
          locale={this.props.locale}
          name="newsletter"
          handleChange={(event) => this.handleChange(event)}
          handleSubmit={(event) => this.handleSubmit(event)}
        >
          <div className="zmw-NewsletterForm">
            <div className="zmw-NewsletterForm_Inputs">
              <div className="zmw-InputText">
                <label
                  className="zmw-InputText_Label"
                  htmlFor="newsletter-form-email"
                >
                  {this.props.labels.labels.email}
                </label>
                <input
                  className="zmw-InputText_Input"
                  id="newsletter-form-email"
                  name="email"
                  placeholder={this.props.labels.placeholders.email}
                  value={this.state.name}
                  onChange={(event) => this.handleChange(event)}
                  type="email"
                  required
                />
              </div>
            </div>
            <button
              className="zmw-NewsletterForm_Button zmw-Button"
              type="submit"
            >
              {this.props.labels.submit}
            </button>
          </div>
          {this.state.status === 'error' && (
            <div className="zmw-Form_Message zmw-Form_Message--error">
              <p>{this.props.labels.messages.error}</p>
            </div>
          )}
        </NetlifyForm>
      )
    }
  }
}

export default NewsletterForm;
