import React from 'react';

const HoneypotField = ({ handleChange }) => {
  return (
    <div className='zmw-HoneypotField'>
      <label>Don't fill this field</label>
      <input name='honeypot' type='text' onChange={handleChange} />
    </div>
  );
};

export default HoneypotField;
