import React, { Component } from 'react';
import YouTube from 'react-youtube';
import { Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

class SliderGalleryItem extends Component {
  render () {
    return (
      <Slide index={this.props.index}>
        {this.props.image &&
          <div className='srk-srk-SliderGallery_Image'>
            <img src={this.props.image} alt={this.props.title} />
          </div>
        }
        {this.props.video &&
          <div className='srk-srk-SliderGallery_Video'>
            <YouTube videoId={this.props.video} />
          </div>
        }
        <div className='srk-SliderGallery_Description'>
          <h3>{this.props.title}</h3>
          <div className='srk-SliderGallery_Text'>{this.props.text}</div>
        </div>
      </Slide>
    );
  }
}

export default SliderGalleryItem;
