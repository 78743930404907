import React, { useEffect } from 'react'
import ThankYouHeader from '../ThankYouHeader'
import Newsletter from '../Newsletter'
import Feedback from '../Feedback'

const ThankYou = (props) => {
  const newsletterLabels = {
    header: props.labels.newsletter,
    form: props.forms.newsletter
  }
  const feedbackLabels = {
    header: props.labels.feedback,
    form: props.forms.feedback
  }
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div className="zmw-ThankYou">
      <ThankYouHeader
        amount={props.amount}
        labels={props.labels}
        paymentSlip={props.paymentSlip}
      />
      <Newsletter labels={newsletterLabels} locale={props.locale} />
      <Feedback labels={feedbackLabels} />
    </div>
  )
}

export default ThankYou
