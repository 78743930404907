import React, { Component } from 'react';
import FeedbackForm from '../FeedbackForm';
import SupportForm from '../SupportForm';
import NewsletterForm from '../NewsletterForm';

class AsyncForm extends Component {
  render () {
    let container = null;
    if (this.props.form === 'feedback') {
      container = <FeedbackForm locale={this.props.locale} labels={this.props.translations} />;
    } else if (this.props.form === 'support') {
      container = <SupportForm locale={this.props.locale} labels={this.props.translations} />;
    } else {
      container = <NewsletterForm locale={this.props.locale} labels={this.props.translations} />;
    }
    return (
      <div className='zmw-AsyncForm'>
        {container}
      </div>
    );
  }
}

export default AsyncForm;
