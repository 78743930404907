import { Component } from 'react';
import encode from '../../lib/encode';

class AbstractForm extends Component {
  handleSubmit (event) {
    // Let JS handle the form
    event.preventDefault();

    // Set up the form values for Netlify
    const body = encode(this.state);

    // Send the form data.
    fetch(window.location.href, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body
    })
      .then((response) => {
        if (response.ok) {
          this.setState({ status: 'success' });
        } else {
          this.setState({ status: 'error' });
        }
      })
      .catch(error => console.log(error));
  }

  handleChange (event) {
    this.setState({[event.target.name]: event.target.value});
  }

  render () {
    return null;
  }
}

export default AbstractForm;
