import React from 'react';

const NewsletterHeader = props => {
  return (
    <div className='zmw-ContentBlock'>
      <div className='zmw-ContentBlock_Body'>
        <h2 className='zmw-ContentBlock_Headline'>{props.labels.headline}</h2>
        <div className='zmw-ContentBlock_Content'>{props.labels.body}</div>
      </div>
    </div>
  );
};

export default NewsletterHeader;
