import 'whatwg-fetch';
import Promise from 'promise-polyfill';
import FontFaceObserver from 'fontfaceobserver';
import React from 'react';
import ReactDOM from 'react-dom';
import ZmwDonationWidget from './components/ZmwDonationWidget';
import Navigation from './navigation';
import SocialMediaStream from './components/SocialMediaStream';
import AsyncForm from './components/AsyncForm';
import QuestionAnswer from './components/QuestionAnswer';
import SliderGallery from './components/SliderGallery';

if (!window.Promise) {
  window.promise = Promise;
}

(() => {
  var fontA = new FontFaceObserver('Gibson', { weight: 400 });
  var fontB = new FontFaceObserver('Gibson', { weight: 500 });

  // Optimization for Repeat Views
  if (window.sessionStorage.criticalFoftDataUriFontsLoaded) {
    document.documentElement.className += ' fonts-stage-1';
  } else {
    Promise.all([fontA.load(), fontB.load()])
      .then(() => {
        document.documentElement.className += ' fonts-stage-1';
        // Optimization for Repeat Views
        window.sessionStorage.criticalFoftDataUriFontsLoaded = true;
      });
  }
})();

// Render ZmwDonationWidget
document.addEventListener('DOMContentLoaded', () => {
  // Load navigation event listener
  const navigation = new Navigation();
  navigation.init();

  // Load social media stream
  const socialMediaStreamElements = document.querySelectorAll('[data-module-name="social-media-stream"]');
  if (socialMediaStreamElements.length > 0) {
    SocialMediaStream.init(socialMediaStreamElements);
  }

  // Load React application for donation widget
  Array.from(document.getElementsByClassName('zmw-DonationWidget')).forEach((container) => {
    ReactDOM.render(
      <ZmwDonationWidget
        id={container.id}
      />,
      container
    );
  });
  // Load React applications for forms
  Array.from(document.getElementsByClassName('zmw-AsyncForm')).forEach((container) => {
    ReactDOM.render(
      <AsyncForm
        id={container.id}
        {...window.zmwFormConfig[container.id]}
      />,
      container
    );
  });
  // Load React component for Question & Answer
  Array.from(document.getElementsByClassName('zmw-QA')).forEach((container) => {
    const question = container.querySelector('.zmw-QA_Question').innerHTML;
    const answer = container.querySelector('.zmw-QA_Answer').innerHTML;
    ReactDOM.render(
      <QuestionAnswer
        id={container.id}
        question={question}
        answer={answer}
      />,
      container
    );
  });

  // Load React component for Slider Gallery
  Array.from(document.getElementsByClassName('srk-SliderGallery')).forEach((container) => {
    const items = [];
    const nodesList = container.querySelectorAll('article.srk-SliderGallery_Item');
    Array.prototype.forEach.call(nodesList, (item) => {
      items.push({
        id: item.id,
        title: item.dataset.title,
        text: item.dataset.text,
        image: item.dataset.image,
        video: item.dataset.video
      });
    });

    ReactDOM.render(
      <SliderGallery
        items={items}
      />,
      container.querySelector('.srk-SliderGallery_Container')
    );
  });
});

// tracking code for fonts.com (Project 2xWeihnachten)
var MTIProjectId = '3c8af1e6-d697-4f7a-b488-5eee81016e2f'; // eslint-disable-line no-unused-vars
(function () {
  var mtiTracking = document.createElement('script');
  mtiTracking.type = 'text/javascript';
  mtiTracking.async = 'true';
  mtiTracking.src = '/assets/javascripts/mtiFontTrackingCode.js';
  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mtiTracking);
})();
