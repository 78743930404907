import React, { Component } from 'react';

class QuestionAnswer extends Component {
  constructor (props) {
    super(props);
    this.state = { visible: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick () {
    this.setState({ visible: !this.state.visible });
  }

  render () {
    return (
      <div className={this.state.visible ? 'zmw-QA visible' : 'zmw-QA'}>
        <div onClick={this.handleClick} className='zmw-QA_Question' aria-expanded={this.state.visible} aria-controls={`QA-${this.props.id}`}>{this.props.question}</div>
        <div className='zmw-QA_Answer' dangerouslySetInnerHTML={{__html: this.props.answer}} id={`QA-${this.props.id}`} aria-hidden={!this.state.visible} />
      </div>
    );
  }
}

export default QuestionAnswer;
