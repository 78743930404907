import React, { Component } from 'react'
import { DonationWidget } from 'donation-widget'
import RaiseNowWidget from '../RaiseNowWidget'
import ThankYou from '../ThankYou'
class ZmwDonationWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    // Always load the First page in the flow since there's no state info
    let eppTransactionId, hasPrefillParams
    if (window.location.search) {
      // do not use searchparams for compatibility reasons
      const queryParams = window.location.search.substring(1).split('=')
      queryParams.forEach((queryParam, index) => {
        if (queryParam.match(/epp_transaction_id/)) {
          const eppTransactionIdIndex = index + 1
          eppTransactionId = queryParams[eppTransactionIdIndex]
        }
        if (queryParam.match(/rnw-/)) {
          hasPrefillParams = true
        }
      })
    }
    if (eppTransactionId || hasPrefillParams) {
      this.state.page = 'checkout'
    } else {
      this.state.page = 'donation-widget'
    }
    this.state.amount = 0
    this.state.monthly = false
    const donationWidgetConfig = window.zmwDwConfig[this.props.id]
    // Add splitVersion to product list
    donationWidgetConfig.campaign =
      window.zmwDwConfig[this.props.id].campaign +
      '-' +
      window.zmwDwConfig[this.props.id].branch
    donationWidgetConfig.progressBar = {
      successAmount: window.zmwDwConfig[this.props.id].successamount,
      theme: {
        success: {
          color: '#24c552'
        },
        active: {
          color: '#0897ea',
          trailColor: '#d8ce9d'
        },
        default: {
          color: '#d8ce9d'
        }
      }
    }
    // append progressBar config
    this.config = {
      campaign: window.zmwDwConfig[this.props.id].campaign,
      raiseNow: window.zmwDwConfig[this.props.id].raiseNow,
      donationWidget: donationWidgetConfig,
      thankYou: window.zmwDwConfig[this.props.id].thankYou,
      environment: window.zmwDwConfig[this.props.id].environment
    }
    this.donationWidgetState = {}
    this.toggleClassOnBody()
  }

  onDonate(amount, monthly, currentState, gAEcommerceItems) {
    if (amount > 0) {
      this.donationWidgetState = Object.assign({}, currentState)
      this.setState({
        amount,
        donationItems: currentState.donationItems,
        page: 'checkout',
        monthly: monthly.selected,
        gAEcommerceItems: gAEcommerceItems
      })
      // Push new state to history stack
      history.pushState('raise-now', null, `${window.location.pathname}#donate`)
    }
  }
  componentDidUpdate() {
    this.toggleClassOnBody()
  }

  onPaymentSuccess(amount, paymentSlip) {
    if (amount > 0) {
      this.setState({
        amount,
        paymentSlip,
        page: 'thank-you'
      })
      // Push new state to history stack
      history.pushState(
        'thank-you',
        null,
        `${window.location.pathname}#thank-you`
      )
    }
  }

  // Used to toggle className on body when checkout container is displayed
  toggleClassOnBody() {
    const { page } = this.state
    if (page === 'checkout') {
      document.body.classList.remove('js-on-thank-you')
      document.body.classList.add('js-on-checkout')
      window.scrollTo(0, 0)
    } else if (page === 'thank-you') {
      document.body.classList.remove('js-on-checkout')
      document.body.classList.add('js-on-thank-you')
      window.scrollTo(0, 0)
    } else {
      document.body.classList.remove('js-on-thank-you')
      document.body.classList.remove('js-on-checkout')
    }
  }

  render() {
    let container = null
    // Container depends on state
    if (this.state.page === 'checkout') {
      container = (
        <RaiseNowWidget
          amount={this.state.amount}
          donationItems={this.state.donationItems}
          environment={this.config.environment}
          onPaymentSuccess={(amount, paymentSlip) =>
            this.onPaymentSuccess(amount, paymentSlip)
          }
          monthly={this.state.monthly}
          gAEcommerceItems={this.state.gAEcommerceItems}
          config={this.config}
          {...this.config.raiseNow}
        />
      )
    } else if (this.state.page === 'donation-widget') {
      container = (
        <DonationWidget
          onDonate={(amount, monthly, currentState, gAEcommerceItems) =>
            this.onDonate(amount, monthly, currentState, gAEcommerceItems)
          }
          currentState={this.donationWidgetState}
          {...this.config.donationWidget}
        />
      )
    } else {
      container = (
        <ThankYou
          locale={this.config.donationWidget.locale}
          amount={this.state.amount}
          paymentSlip={this.state.paymentSlip}
          {...this.config.thankYou}
        />
      )
    }
    return <div className="zmw-DonationWidget">{container}</div>
  }
}

export default ZmwDonationWidget
