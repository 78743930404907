import React from 'react';
import PaymentSlip from '../PaymentSlip';

const ThankYouHeader = props => {
  const amountMarkUp = `<span class='zmw-HeaderBlock_Amount'>${props.amount}.– CHF</span>`;
  // ${amount} should be defined in yaml translation files.
  const thankYouHeaderLabel = props.labels.header.label.replace('${amount}', amountMarkUp); // eslint-disable-line no-template-curly-in-string
  return (
    <header className='zmw-HeaderBlock zmw-HeaderBlock-withoutImage'>
      <div className='zmw-HeaderBlock_Body'>
        <h1 className='zmw-HeaderBlock_Title'>{props.labels.header.headline}</h1>
        <p className='zmw-HeaderBlock_Content' dangerouslySetInnerHTML={{__html: thankYouHeaderLabel}} />
        { props.paymentSlip &&
          <PaymentSlip
            url={props.paymentSlip}
            label={props.labels.payment_slip}
          />
        }
      </div>
    </header>
  );
};

export default ThankYouHeader;
