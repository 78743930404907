import React, { Component } from 'react';
import HoneypotField from '../HoneypotField';

class NetlifyForm extends Component {
  render() {
    return (
      <form
        className="zmw-Form"
        name={this.props.name}
        data-netlify="true"
        data-netlify-honeypot="honeypot"
        onSubmit={this.props.handleSubmit}
      >
        <div className="zmw-HiddenFields">
          <HoneypotField handleChange={this.props.handleChange} />
        </div>
        {this.props.children}
        {this.props.locale && (
          <input type="hidden" name="locale" value={this.props.locale} />
        )}
      </form>
    )
  }
}

export default NetlifyForm;
