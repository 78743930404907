import React, { Component } from "react";
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import SliderGalleryItem from "./SliderGalleryItem";
import "pure-react-carousel/dist/react-carousel.es.css";

class SliderGallery extends Component {
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={window.innerWidth >= 480 ? 80 : 120}
        isIntrinsicHeight={true}
        totalSlides={this.props.items.length}
      >
        <Slider>
          {this.props.items.map((object, i) => {
            return <SliderGalleryItem {...{ index: i, ...object }} key={i} />;
          })}
        </Slider>
        <ButtonBack>
          <svg viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              className="arrow"
            />
          </svg>
        </ButtonBack>
        <ButtonNext>
          <svg viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              className="arrow"
              transform="translate(100, 100) rotate(180) "
            />
          </svg>
        </ButtonNext>
      </CarouselProvider>
    );
  }
}

export default SliderGallery;
