import React from 'react';
import NewsletterHeader from '../NewsletterHeader';
import NewsletterForm from '../NewsletterForm';

const Newsletter = props => {
  return (
    <div className="zmw-ContentSection zmw-ContentSection-informational">
      <div className="zmw-ContentSection_Container">
        <NewsletterHeader labels={props.labels.header} />
        <NewsletterForm locale={props.locale} labels={props.labels.form} />
      </div>
    </div>
  )
};

export default Newsletter;
