/* global XMLHttpRequest */

var SocialMediaStream = {
  init: function (socialMediaStreamElements) {
    Array.prototype.forEach.call(socialMediaStreamElements, function (socialMediaStreamContainer) {
      var socialMediaStreamContentEl = socialMediaStreamContainer.querySelector('.ce-SocialMediaStream_ItemsContent');
      var setHtmlToStreamElement = function (responseData) {
        socialMediaStreamContentEl.insertAdjacentHTML('beforeend', responseData.html);
      };
      var container = {
        loadingClass: 'is-Loading',
        addLoadingClass: function () {
          socialMediaStreamContainer.classList.add(this.loadingClass);
        },
        removeLoadingClass: function () {
          socialMediaStreamContainer.classList.remove(this.loadingClass);
        }
      };

      var loadMore = {
        el: {},
        clicked: false,
        created: false,
        init: function () {
          var self = this;
          this.el = socialMediaStreamContainer.querySelector('.ce-SocialMediaStream_LoadMore');
          this.created = true;
          if (googleScriptApi.page.length > 1) {
            this.show();
          }
          this.el.addEventListener('click', function () {
            if (self.clicked === false) {
              self.clicked = true;
              googleScriptApi.getContent(googleScriptApi.page.currentPage + 1, function () {
                self.clicked = false;
              });
            }
          });
        },
        show: function () {
          this.el.setAttribute('aria-hidden', 'false');
        },
        hide: function () {
          this.el.setAttribute('aria-hidden', 'true');
        }
      };

      var googleScriptApi = {
        baseUrl: 'https://script.google.com/macros/s/AKfycbzI-G7CTkZCwlRE6UCm5fthqtgF8hg6X7Ty4cVFDPUTXxjyX-8/exec',
        page: {
          currentPage: 0,
          length: 1
        },
        list: '',
        userName: '',
        getContent: function (page) {
          var request = new XMLHttpRequest();

          var self = this;
          container.addLoadingClass();

          request.onreadystatechange = function () {
            if (request.readyState === 4 && request.status === 200) {
              var responseData = JSON.parse(request.responseText);
              self.page.length = responseData.pages;
              self.page.currentPage = self.page.currentPage + 1;
              setHtmlToStreamElement(responseData);
              if (loadMore.created === false) {
                loadMore.init();
              } else if (self.page.currentPage === responseData.pages) {
                loadMore.hide();
              } else if (self.page.length > 1) {
                loadMore.show();
              }
            }

                  // reset state
            if (request.readyState === 4) {
              loadMore.clicked = false;
              container.removeLoadingClass();
            }
          };

          request.open('GET', this.getUrl(page), true);
          request.send();
        },
        getUrl: function (page) {
          var listParam = this.list ? '&list=' + this.list : '';
          var userName = this.userName ? '&userName=' + this.userName : '';
          var language = !this.userName ? '&language=' + document.documentElement.getAttribute('lang').split('-')[0] : '';
          var requestPage = page || this.page.currentPage;
          var baseUrl = this.baseUrl + '?page=' + requestPage + listParam + userName + language;

          return baseUrl;
        },
        setList: function (list) {
          this.list = list;
        },
        setUserName: function (userName) {
          this.userName = userName;
        }
      };

      var socialMediaList = socialMediaStreamContainer.getAttribute('data-social-media-aggregator-list');
      if (socialMediaList) {
        googleScriptApi.setList(socialMediaList);
      }
      var socialMediaUserName = socialMediaStreamContainer.getAttribute('data-social-media-aggregator-user');
      if (socialMediaUserName) {
        googleScriptApi.setUserName(socialMediaUserName);
      }
      googleScriptApi.getContent();
    });
  }
};

export default SocialMediaStream;
