import React from 'react';
import FeedbackHeader from '../FeedbackHeader';
import FeedbackForm from '../FeedbackForm';

const Feedback = props => {
  return (
    <div className='zmw-ThankYou_Feedback'>
      <FeedbackHeader
        labels={props.labels.header}
      />
      <FeedbackForm
        labels={props.labels.form}
      />
    </div>
  );
};

export default Feedback;
