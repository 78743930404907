import React from 'react';
import NetlifyForm from '../NetlifyForm';
import AbstractForm from '../AbstractForm';

class FeedbackForm extends AbstractForm {
  constructor (props) {
    super(props);
    this.state = {
      'form-name': 'feedback',
      'locale': props.locale,
      'name': '',
      'email': '',
      'message': ''
    };
  }

  render () {
    if (this.state.status === 'success') {
      return (
        <div className='zmw-Form_Message zmw-Form_Message--success'>
          <p>{this.props.labels.messages.success}</p>
        </div>
      );
    } else {
      return (
        <NetlifyForm
          locale={this.props.locale}
          name='feedback'
          handleChange={(event) => this.handleChange(event)}
          handleSubmit={(event) => this.handleSubmit(event)}
        >
          <div className='zmw-FeedbackForm'>
            <div className='zmw-FeedbackForm_Inputs'>
              <div className='zmw-InputText'>
                <input
                  className='zmw-InputText_Input'
                  id='feedback-form-name'
                  name='name'
                  placeholder={this.props.labels.placeholders.name}
                  value={this.state.name}
                  onChange={(event) => this.handleChange(event)}
                  type='text'
                  required
                />
                <label className='zmw-InputText_Label' htmlFor='feedback-form-name'>
                  {this.props.labels.labels.name}
                </label>
              </div>
              <div className='zmw-InputText'>
                <input
                  className='zmw-InputText_Input'
                  id='feedback-form-email'
                  name='email'
                  placeholder={this.props.labels.placeholders.email}
                  value={this.state.email}
                  onChange={(event) => this.handleChange(event)}
                  type='email'
                  required
                />
                <label className='zmw-InputText_Label' htmlFor='feedback-form-email'>
                  {this.props.labels.labels.email}
                </label>
              </div>
              <div className='zmw-TextArea'>
                <textarea
                  className='zmw-TextArea_Input'
                  id='feedback-form-message'
                  name='message'
                  placeholder={this.props.labels.placeholders.message}
                  value={this.state.message}
                  onChange={(event) => this.handleChange(event)}
                  required
                />
                <label className='zmw-InputText_Label' htmlFor='feedback-form-message'>
                  {this.props.labels.labels.message}
                </label>
              </div>
            </div>
            <button className='zmw-SupportForm_Button zmw-Button' type='submit'>
              {this.props.labels.submit}
            </button>
          </div>
          {(this.state.status === 'error') &&
            <div className='zmw-Form_Message zmw-Form_Message--error'>
              <p>{this.props.labels.messages.error}</p>
            </div>
          }
        </NetlifyForm>
      );
    }
  }
}

export default FeedbackForm;
