class Navigation {
  constructor () {
    this.toggleClass = 'js-zmw-MainMenu--isOpen';
    this.navToggles = document.querySelectorAll('.js-zmw-MainMenu_Toggle');

    if (this.navToggles && this.navToggles.length) {
      this.navTarget = document.getElementById(this.navToggles[0].getAttribute('aria-controls'));
    }
  }

  init () {
    if (this.navTarget) {
      this.addEventListenerOnNavToggle();
    }
  }

  addEventListenerOnNavToggle () {
    [...this.navToggles].map(item => {
      item.addEventListener('click', () => {
        if (this.navTarget.getAttribute('aria-expanded') === 'false') {
          document.addEventListener('click', evt => this.handlerClickEvent(evt), false);
          this.navTarget.setAttribute('aria-expanded', 'true');
          document.documentElement.classList.add(this.toggleClass)
        } else {
          this.navTarget.setAttribute('aria-expanded', 'false');
          document.documentElement.classList.remove(this.toggleClass)
          document.removeEventListener('click', evt => this.handlerClickEvent(evt), false);
        }
      });
    });
  }

  handlerClickEvent (event) {
    let navBlockTested = event.target;
    let hasToBeToggled = false;
    // Check if clicked element's ancestors is not navToggles or menu dropdown
    do {
      this.navToggles.forEach((node) => {
        if (navBlockTested === node || navBlockTested === this.navTarget) {
          hasToBeToggled = true;
        }
      });
      if (hasToBeToggled) return;
      navBlockTested = navBlockTested.parentNode;
    } while (navBlockTested);

    this.navTarget.setAttribute('aria-expanded', 'false');
    document.body.classList.remove(this.toggleClass);
    document.removeEventListener('click', this.handlerClickEvent);
  }
}

export default Navigation;
