import React from 'react';

const PaymentSlip = ({ url, label }) => (
  <div className='zmw-CallToAction'>
    <a
      href={url}
      className='zmw-CallToAction_Link'
      target='_blank'
      rel='noopener noreferrer'
    >{label}</a>
  </div>
);

export default PaymentSlip;
